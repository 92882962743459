import { useContext, useEffect } from "react";
import UploadGallery from "../staff/files/Photos/UploadGallery";
import { AppContext } from "../../App";
import { SMDashboardContext } from "./Dashboard";

const SMPhotos = () => {
    const { loggedInUser } = useContext(AppContext);
    const { setCurrentPage, addToast } = useContext(SMDashboardContext);
    setCurrentPage("photos");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top "
        >
            <UploadGallery addToast={addToast} user={loggedInUser} userType={"split-members"} owner={true} returnButton={true} />
        </div>
    );
};

export default SMPhotos;

import { useEffect, useState, useRef, useCallback, useContext } from "react";
import { Ethnicities, EthnicitiesMap, FriendlySlugTranslation, JewishAncestries, RaceMap, SchoolRanking, SchoolRankingMap } from "../../util/helpers";
import { Filter } from "../filter/Filter";
import { DropdownSearchFilter } from "../filter/DropdownSearchFilter";
import { MinMaxFilter } from "../filter/MinMaxFilter";
import { MultiCheckboxFilter } from "../filter/MultiCheckboxFilter";
import { MultiButtonFilter } from "../filter/MultiButtonFilter";
import { MultiButtonGridFilter } from "../filter/MultiButtonGridFilter";
import { DisclosureFilter } from "../filter/DisclosureFilter";
import { InputFilter } from "../filter/InputFilter";
import { SavedSearches } from "../filter/SavedSearches";
import { SimpleModal } from "../modals/SimpleModal";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";
import { DropdownFilter } from "../filter/DropdownFilter";
import { SingleSelectCheckboxFilter } from "../filter/SingleSelectCheckboxFilter";
import { useNavigate } from "react-router-dom";

const DonorSearch = () => {
    const navigate = useNavigate();
    const [donors, setDonors] = useState([]);
    const [favorites, setFavorites] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [hasError, setHasError] = useState(null);

    const [initialReqDone, setInitialReqDone] = useState(false);
    const [loading, setLoading] = useState(false);

    const [mobileFilter, setMobileFilter] = useState(false);
    const [haveFilters, setHaveFilters] = useState(false);

    const [selectedStates, setSelectedStates] = useState([]);

    const [heritageFilterFocus, setHeritageFilterFocus] = useState(false);
    const [heritageInput, setHeritageInput] = useState("");
    const [selectedHeritages, setSelectedHeritages] = useState([]);

    const [filterOptions, setFilterOptions] = useState(null);
    const [filters, setFilters] = useState([]);
    const [flagsFilter, setFlagsFilter] = useState([]);
    const [raceFilter, setRaceFilter] = useState([]);
    const [hairColorFilter, setHairColorFilter] = useState([]);
    const [hairTypeFilter, setHairTypeFilter] = useState([]);
    const [eyeColorFilter, setEyeColorFilter] = useState([]);
    const [educationFilter, setEducationFilter] = useState([]);
    const [rankingFilter, setRankingFilter] = useState([]);
    const [personalityFilter, setPersonalityFilter] = useState([]);
    const [aptitudeFilter, setAptitudeFilter] = useState([]);
    const [jewishAncestryFilter, setJewishAncestryFilter] = useState("");
    const [disclosureFilter, setDisclosureFilter] = useState([]);

    const searchRef = useRef(null);
    const [searches, setSearches] = useState([]);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [savedSearchModal, setSavedSearchModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [refetchSearches, setRefetchSearches] = useState(0);

    const [updateDateRange, setUpdateDateRange] = useState([null, null]);

    const [refetchFaves, setRefetchFaves] = useState(0);
    const [refetchDonors, setRefetchDonors] = useState(0);
    const [skipScroll, setSkipScroll] = useState(false);

    const [orderBy, setOrderBy] = useState("most_recently_active_at");
    const [orderDir, setOrderDir] = useState("desc");

    const [banner, setBanner] = useState(localStorage.getItem("dsBannerSeen") === null);

    const heritageDropdownRef = useRef(null);
    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage, addToast } = useContext(IPDashboardContext);

    setCurrentPage("donors");

    useEffect(() => {
        if (loggedInUser && loggedInUser.intended_parent.family.status === "matched" && !loggedInUser.intended_parent.family.multimatch) {
            navigate("/family/checklist");
        }
    }, [loggedInUser, navigate]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    const getFilterString = useCallback(() => {
        let filterString = "";

        if (filters.length) {
            filters.forEach((filter) => {
                if (filter.name.includes("range")) {
                    if (filter.value[0] !== null) {
                        filterString += `&${filter.name}_min=${filter.value[0]}`;
                    }
                    if (filter.value[1] !== null) {
                        filterString += `&${filter.name}_max=${filter.value[1]}`;
                    }
                } else if (filter.name === "status" || filter.name === "frozen") {
                    filter.value.forEach((v) => {
                        filterString += `&${filter.name}=${v}`;
                    });
                } else {
                    filterString += `&${filter.name}=${filter.value}`;
                }
            });
        }

        if (selectedStates.length) {
            selectedStates.forEach((item) => (filterString += `&states=${item}`));
        }
        if (selectedHeritages.length) {
            selectedHeritages.forEach((item) => (filterString += `&heritages=${item}`));
        }
        if (flagsFilter.length) {
            flagsFilter.forEach((item) => (filterString += `&flags=${item}`));
        }
        if (raceFilter.length) {
            raceFilter.forEach((item) => (filterString += `&race=${item}`));
        }
        if (hairColorFilter.length) {
            hairColorFilter.forEach((item) => (filterString += `&hair_color=${item}`));
        }
        if (hairTypeFilter.length) {
            hairTypeFilter.forEach((item) => (filterString += `&hair_type=${item}`));
        }
        if (eyeColorFilter.length) {
            eyeColorFilter.forEach((item) => (filterString += `&eye_color=${item}`));
        }
        if (educationFilter.length) {
            educationFilter.forEach((item) => (filterString += `&education_level=${item}`));
        }
        if (rankingFilter.length) {
            rankingFilter.forEach((item) => (filterString += `&school_ranking=${item}`));
        }
        if (personalityFilter.length) {
            personalityFilter.forEach((item) => (filterString += `&personality=${item}`));
        }
        if (aptitudeFilter.length) {
            aptitudeFilter.forEach((item) => (filterString += `&aptitude=${item}`));
        }
        if (jewishAncestryFilter.length) {
            filterString += `&jewish_ancestry=${jewishAncestryFilter}`;
        }
        if (disclosureFilter.length) {
            disclosureFilter.forEach((item) => (filterString += `&disclosure=${item}`));
        }

        if (filterString.length) {
            setHaveFilters(true);
        } else {
            setHaveFilters(false);
        }

        return filterString;
    }, [
        filters,
        flagsFilter,
        raceFilter,
        hairColorFilter,
        hairTypeFilter,
        eyeColorFilter,
        educationFilter,
        rankingFilter,
        personalityFilter,
        aptitudeFilter,
        jewishAncestryFilter,
        disclosureFilter,
        selectedStates,
        selectedHeritages,
    ]);

    useEffect(() => {
        const fetchSearches = async () => {
            try {
                const response = await request({
                    url: `/family/donors/searches`,
                    method: "GET",
                });

                const { searches } = response;

                setSearches(searches);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchSearches();
    }, [request, refetchSearches]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/family/donors/favorites`,
                    method: "GET",
                });

                const { favorites } = response;

                let faves = {};

                favorites.forEach((f) => (faves[f.favorite_id] = true));

                setFavorites(faves);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchFavorites();
    }, [request, refetchFaves]);

    useEffect(() => {
        document.title = "Donor Search";

        const fetchDonors = async () => {
            setLoading(true);
            const filterString = getFilterString();

            try {
                const response = await request({
                    url: `/family/donors?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { donors } = response;

                if (page !== 1) {
                    setDonors((prev) => [...prev, ...donors]);
                } else {
                    setDonors(donors);
                }

                if (!initialReqDone) {
                    setInitialReqDone(true);
                }
                setHasMore(donors.length > 0);
                setLoading(false);
                // window.history.replaceState(null, '', `${window.location.pathname}?page=${page}${window.location.search}`)
            } catch (error) {
                // TODO: handle this error
                setLoading(false);
                console.error("Error fetching checklist:", error);
            }
        };

        fetchDonors();
    }, [request, page, orderBy, orderDir, getFilterString, initialReqDone]);

    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                const response = await request({
                    url: `/family/donors/filter-options`,
                    method: "GET",
                });

                const { filterOptions } = response;
                setFilterOptions(filterOptions);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchFilterOptions();
    }, [request]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (heritageDropdownRef.current && !heritageDropdownRef.current.contains(event.target)) {
                setHeritageFilterFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const setFilterArray = (stateArray, setStateArray, value) => {
        let filterArray = structuredClone(stateArray);

        if (filterArray.includes(value)) {
            filterArray = filterArray.filter((item) => item !== value);
        } else {
            filterArray.push(value);
        }

        window.scrollTo({
            top: 0,
        });
        setStateArray(filterArray);
        setPage(1);
    };

    const setMinMax = (name, event) => {
        let val = event.target.value;

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (val.length) {
            if (name === "height_min" || name === "height_max") {
                val = val.replace(/[^0-9']/g, "");
                const h = filters.filter((f) => f.name === name);
                if (h.length && val.length < h[0].value.length && h[0].value.endsWith("'") && !val.endsWith("'")) {
                    // Do nothing, allow the single quote deletion
                } else if (val.length > 0 && !val.includes("'")) {
                    const lastChar = val[val.length - 1];
                    if (!isNaN(lastChar)) {
                        val += "'";
                    }
                }
            }

            filteredFilters.push({
                name: name,
                value: val,
            });
        }

        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setFilters(filteredFilters);
    };

    const clearAllFilters = () => {
        window.scrollTo({
            top: 0,
        });
        setSelectedSearch("");
        setFilters([]);
        setRaceFilter([]);
        setHairColorFilter([]);
        setHairTypeFilter([]);
        setEyeColorFilter([]);
        setEducationFilter([]);
        setRankingFilter([]);
        setPersonalityFilter([]);
        setAptitudeFilter([]);
        setJewishAncestryFilter("");
        setDisclosureFilter([]);
        setUpdateDateRange([null, null]);
        setFlagsFilter([]);
        setSelectedStates([]);
        setHeritageInput("");
        setSelectedHeritages([]);
        setPage(1);
    };

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });

            // window.history.replaceState(null, '', `${window.location.pathname.includes('?') ? `${window.location.pathname}&name=${search}` : `${window.location.pathname}?name=${search}`}`)
        } else {
            // window.history.replaceState(null, '', window.location.pathname.replace('?name=', ''))
            // window.history.replaceState(null, '', window.location.pathname.replace('&name=', ''))
        }

        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setFilters(filteredFilters);
    };

    const handleInputChange = (event, setInputState) => {
        const input = event.target.value;

        setInputState(input);
    };

    const updateSelectedHeritages = (heritage) => {
        const filteredHeritages = structuredClone(selectedHeritages).filter((selected) => selected !== heritage);

        if (!selectedHeritages.includes(heritage)) {
            filteredHeritages.push(heritage);
        }

        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setHeritageInput("");
        setSelectedHeritages(filteredHeritages);
        setHeritageFilterFocus(false);
    };

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleString = (s) => {
        if (FriendlySlugTranslation[s]) {
            return FriendlySlugTranslation[s];
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const toggleFavorite = async (donor, undo) => {
        try {
            await request({
                url: `/family/donors/${donor.id}/favorite`,
                method: "PUT",
            });

            // addToast({ heading: undo ? "Unfavorited!" : "Favorited", message: "Donor " + donor.donorCode + (undo ? " unfavorited" : " favorited") });
            setRefetchFaves((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const clearDropdownSearch = (setInput, setSelected) => {
        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setInput("");
        setSelected([]);
    };

    const clearMinMax = (name) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        window.scrollTo({
            top: 0,
        });
        setPage(1);
        setFilters(filteredFilters);
    };

    const closeSaveSearchModal = () => {
        setSearchName("");
        setSavedSearchModal(false);
    };

    const setCriteria = () => {
        let filter = {};

        if (raceFilter.length) {
            filter.race = raceFilter;
        }

        if (hairColorFilter.length) {
            filter.hair_color = hairColorFilter;
        }

        if (hairTypeFilter.length) {
            filter.hair_type = hairTypeFilter;
        }

        if (eyeColorFilter.length) {
            filter.eye_color = eyeColorFilter;
        }

        if (educationFilter.length) {
            filter.education_level = educationFilter;
        }

        if (rankingFilter.length) {
            filter.school_ranking = rankingFilter;
        }

        if (jewishAncestryFilter.length) {
            filter.jewish_ancestry = jewishAncestryFilter;
        }

        if (disclosureFilter.length) {
            filter.relationship_preferences = disclosureFilter;
        }

        if (aptitudeFilter.length) {
            filter.aptitude = aptitudeFilter;
        }

        if (personalityFilter.length) {
            filter.personality = personalityFilter;
        }

        if (selectedHeritages.length) {
            filter.ethnicities = selectedHeritages;
        }

        if (updateDateRange[0] !== null && updateDateRange[1] !== null) {
            filter.updated_range = updateDateRange;
        }

        if (filters.filter((f) => f.name === "height_min" || f.name === "height_max").length) {
            filter.height = filters.filter((f) => f.name === "height_min" || f.name === "height_max");
        }

        return filter;
    };

    const setFilterJson = () => {
        let filter = {};

        filter.race = setRaceFilter;
        filter.hair_color = setHairColorFilter;
        filter.hair_type = setHairTypeFilter;
        filter.eye_color = setEyeColorFilter;
        filter.education_level = setEducationFilter;
        filter.school_ranking = setRankingFilter;
        filter.jewish_ancestry = setJewishAncestryFilter;
        filter.relationship_preferences = setDisclosureFilter;
        filter.aptitude = setAptitudeFilter;
        filter.personality = setPersonalityFilter;
        filter.ethnicities = setSelectedHeritages;
        filter.updated_range = setUpdateDateRange;
        filter.height = setFilters;

        return filter;
    };

    const getEthnicities = (answer, races) => {
        if (!answer || !answer.value) {
            return "";
        }

        let result = "";

        answer.value.forEach((e) => {
            if (e === "african_american" && races["black_african_american"]) {
                return;
            } else if (e === "alaskan_native" && races["indigenous_american_alaska_native"]) {
                return;
            } else if (e === "indigenous_american" && races["indigenous_american_alaska_native"]) {
                return;
            } else if (races[e]) {
                return;
            }

            races[e] = true;
            result += `, ${EthnicitiesMap[e]}`;
        });

        return result;
    };

    const saveSearch = async () => {
        try {
            await request({
                url: `/family/donors/searches`,
                method: "POST",
                data: {
                    name: searchName,
                    userID: loggedInUser.id,
                    criteria: setCriteria(),
                },
            });

            // addToast({ heading: `Search '${searchName}' saved!`, message: "" });
            setRefetchSearches((prev) => prev + 1);
            setSavedSearchModal(false);
            setSearchName("");
        } catch (error) {
            // TODO: handle this error
            console.error("Error fetching checklist:", error);
        }
    };

    const clearSelectedSearch = () => {
        setSelectedSearch(null);
        clearAllFilters();
    };

    const closeBanner = () => {
        setBanner(false);
        localStorage.setItem("dsBannerSeen", true);
    };

    return (
        <div id="inner-body" className="min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top ">
            {savedSearchModal ? (
                <SimpleModal
                    title="Save filter"
                    name="Name"
                    bodyText={"Tip: Consider saving multiple filters, one with any “must have” donor features and others with features that you’d like the donor to have, but may be more flexible on."}
                    inputs={[{ title: "Name", value: searchName, fullWidth: true, setInputState: setSearchName, handleChange: handleInputChange, placeholder: "Name your filter" }]}
                    close={closeSaveSearchModal}
                    save={saveSearch}
                />
            ) : null}

            {infoModal ? (
                <SimpleModal
                    title="About saved filters"
                    bodyText={
                        "Use the filters to reflect your match preferences and hit “Save filters.” This will allow you to come back to this search without inputting the filters again.\n\nTip: Consider saving multiple filter combinations, one with any must have donor features and others with features that you’d like the donor to have, but may be more flexible on.\n\nIf you've opted into automated donor alerts, your saved filters will dictate which donor profiles are included in the 'New Donor' email digests."
                    }
                    save={() => setInfoModal(false)}
                    saveText="Got it"
                />
            ) : null}

            <div id="app" className="pb-10">
                <div className={`${mobileFilter ? "" : "hidden"} md:block`}>
                    <Filter clearAllFilters={clearAllFilters} haveFilters={haveFilters} setMobileFilter={setMobileFilter}>
                        {[
                            <SavedSearches
                                searchRef={searchRef}
                                searches={searches}
                                selectedSearch={selectedSearch}
                                setSelectedSearch={setSelectedSearch}
                                setSavedSearchModal={setSavedSearchModal}
                                clearSelectedSearch={clearSelectedSearch}
                                searchDropdownOpen={searchDropdownOpen}
                                setSearchDropdownOpen={setSearchDropdownOpen}
                                filters={setFilterJson()}
                                haveFilters={haveFilters}
                                setInfoModal={setInfoModal}
                            />,
                            <MultiCheckboxFilter
                                title="Race"
                                options={filterOptions ? filterOptions.races : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={raceFilter}
                                setSelected={setRaceFilter}
                            />,
                            <DropdownSearchFilter
                                title="Heritages"
                                placeholder="Heritage..."
                                dropdownRef={heritageDropdownRef}
                                filterFocus={heritageFilterFocus}
                                setFilterFocus={setHeritageFilterFocus}
                                input={heritageInput}
                                setInput={setHeritageInput}
                                handleInputChange={handleInputChange}
                                options={Ethnicities}
                                optionsMap={EthnicitiesMap}
                                selected={selectedHeritages}
                                setSelected={setSelectedHeritages}
                                updateSelected={updateSelectedHeritages}
                                clearSelected={clearDropdownSearch}
                            />,
                            <SingleSelectCheckboxFilter title="Jewish ancestry" options={JewishAncestries} setPage={setPage} selected={jewishAncestryFilter} setSelected={setJewishAncestryFilter} />,
                            // <DropdownFilter title="Jewish Ancestry" options={JewishAncestries} selected={jewishAncestryFilter} defaultOption={"All donors"} setSelected={setJewishAncestryFilter} />,
                            <MultiCheckboxFilter
                                title="Hair color"
                                options={filterOptions ? filterOptions.hairColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={hairColorFilter}
                                setSelected={setHairColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Eye color"
                                options={filterOptions ? filterOptions.eyeColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={eyeColorFilter}
                                setSelected={setEyeColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Level of education"
                                options={filterOptions ? filterOptions.education : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={educationFilter}
                                setSelected={setEducationFilter}
                            />,
                            <MultiCheckboxFilter title="School ranking" options={SchoolRanking} grid={false} setFilterArray={setFilterArray} selected={rankingFilter} setSelected={setRankingFilter} />,
                            <MinMaxFilter title="Height" isText={true} filters={filters} nameMin="height_min" nameMax="height_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <MultiButtonFilter
                                title="Hair style"
                                options={filterOptions ? filterOptions.hairTypes : []}
                                width="w-[20%]"
                                setFilterArray={setFilterArray}
                                selected={hairTypeFilter}
                                setSelected={setHairTypeFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Personality traits"
                                items={[{ slug: "logical" }, { slug: "creative" }, { slug: "silly" }, { slug: "serious" }, { slug: "introverted" }, { slug: "extroverted" }]}
                                setFilterArray={setFilterArray}
                                selected={personalityFilter}
                                setSelected={setPersonalityFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Aptitude"
                                items={[
                                    { slug: "mathematical_ability", icon: "math_filter_icon" },
                                    { slug: "scientific_ability", icon: "science_filter_icon" },
                                    { slug: "athletic_ability", icon: "athletic_filter_icon" },
                                    { slug: "singing_ability", icon: "singing_filter_icon" },
                                    { slug: "artistic_ability", icon: "art_filter_icon" },
                                ]}
                                setFilterArray={setFilterArray}
                                selected={aptitudeFilter}
                                setSelected={setAptitudeFilter}
                            />,
                            <DisclosureFilter options={filterOptions ? filterOptions.disclosures : []} setFilterArray={setFilterArray} selected={disclosureFilter} setSelected={setDisclosureFilter} />,
                            <InputFilter
                                name="donor_code"
                                title="Donor code"
                                placeholder="3 letter donor code..."
                                clearState={clearNameSearch}
                                clearValue="donor_code"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                        ]}
                    </Filter>
                </div>
                {initialReqDone && !donors.length ? (
                    <div className="min-h-screen flex items-center justify-center sm:w-full md:w-3/4 sm:ml-10 md:ml-[422px] sm:mr-20  md:mr-[48px] bg-white-200">
                        <div className="w-1/2 whitespace-pre-line text-center">
                            No donors are currently available that match your filters.
                            <br />
                            <br />
                            Please broaden your filters to continuing browsing available donors, or save this search to return as new donors become available.
                        </div>
                    </div>
                ) : null}
                {loading ? (
                    <div className="min-h-screen flex items-center justify-center fixed inset-0 z-10 mt-16 sm:mt-20 sm:w-full md:w-3/4 sm:ml-10 md:ml-[422px] sm:mr-20  md:mr-[48px] backdrop-blur-sm">
                        <div className="absolute inset-0 flex justify-center items-center z-10">
                            <img width={65} height={65} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
                        </div>
                    </div>
                ) : null}
                <div className="sm:w-full md:w-3/4 sm:ml-10 md:ml-[422px] sm:mr-20  md:mr-[48px] grid sm:grid-cols-1 md:grid-cols-3 bg-white-200">
                    {banner && donors.length ? (
                        <div className="col-span-1 md:col-span-3 px-4">
                            <div className="hidden w-full pb-4 mx-auto m-8 sm:grid grid-cols-12 bg-banner-gradient bg-cover rounded-xl">
                                <img
                                    className="col-span-1 pb-4 pt-8 justify-self-end mr-2 lg:mr-[25px]"
                                    src={`${process.env.PUBLIC_URL}/images/co_shortened_logo.svg`}
                                    alt=""
                                    width="50"
                                    height="265"
                                />
                                <div className="col-span-9 pb-4 pt-8 pr-4">
                                    <div className="font-semibold text-sm">At Cofertility, we do things differently.</div>
                                    <div className="text-sm">
                                        We’re taking a human-centered approach to egg donation that is focused on meeting your unique needs and honoring everyone involved. In our model, women freeze
                                        their eggs for free when they donate half of the eggs retrieved to your family.{" "}
                                        <a className="underline" href="https://www.cofertility.com/family/understanding-egg-sharing" target="_blank" rel="noreferrer">
                                            Learn more about our stance
                                        </a>
                                        .
                                    </div>
                                </div>
                                <div onClick={closeBanner} className="col-span-1 pt-7 my-auto cursor-pointer justify-self-end">
                                    Got it!
                                </div>
                                <img
                                    onClick={closeBanner}
                                    className="col-span-1 justify-self-end m-2 cursor-pointer"
                                    src={`${process.env.PUBLIC_URL}/images/close_icon.svg`}
                                    alt=""
                                    width="24"
                                    height="265"
                                />
                            </div>

                            <div className="w-full pb-4 mx-auto m-8 sm:hidden bg-banner-gradient flex flex-col bg-cover rounded-xl">
                                <div className="flex justify-between items-start">
                                    <img className="pb-4 pt-4 mx-4" src={`${process.env.PUBLIC_URL}/images/co_shortened_logo.svg`} alt="" width="50" height="265" />
                                    <img onClick={closeBanner} className="justify-self-end m-2 cursor-pointer" src={`${process.env.PUBLIC_URL}/images/close_icon.svg`} alt="" width="24" height="24" />
                                </div>

                                <div className="pb-4 px-4">
                                    <div className="font-semibold text-sm">At Cofertility, we do things differently.</div>
                                    <div className="text-sm">
                                        We’re taking a human-centered approach to egg donation that is focused on meeting your unique needs and honoring everyone involved. In our model, women freeze
                                        their eggs for free when they donate half of the eggs retrieved to your family. Learn more about our stance.
                                    </div>
                                </div>
                                <div onClick={closeBanner} className="pt-7 cursor-pointer ml-auto pr-4">
                                    Got it!
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="md:hidden flex items-center px-5">
                        <button
                            className="flex items-center w-full justify-center bg-white-200 text-black border border-black rounded-lg px-2 py-1 h-12 mt-5 hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                            onClick={() => setMobileFilter(true)}
                        >
                            <svg className="ml-[5px]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 16.25L12 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 16.25L3 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21 8L16.5 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 8L3 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 19.25L7.5 12.5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.5 11.75L16.5 5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div>Filters</div>
                        </button>
                    </div>
                    {donors.map((donor) => {
                        let races = {};
                        return (
                            <div key={donor.id} className="p-5 w-full">
                                <div>
                                    {donor.user.user_photos ? (
                                        <img
                                            onClick={() => window.open(window.location.origin + `/family/donors/${donor.user.id}`, "_blank")}
                                            src={donor.user.user_photos[0].cropped_source}
                                            alt={donor.user.user_photos[0].name}
                                            className="w-full rounded-lg aspect-square object-cover cursor-pointer"
                                        />
                                    ) : null}
                                </div>
                                <div className="flex items-center justify-between mt-3">
                                    <div
                                        className="font-display font-medium text-2xl max-w-fit cursor-pointer"
                                        onClick={() => window.open(window.location.origin + `/family/donors/${donor.user.id}`, "_blank")}
                                    >
                                        {donor.user.donorCode}
                                    </div>
                                    <span className="text-secondary">
                                        {favorites && favorites[donor.user.id] ? (
                                            <svg
                                                onClick={() => toggleFavorite(donor.user, true)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="#ED795E"
                                                class="bi bi-heart-fill cursor-pointer"
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                            </svg>
                                        ) : (
                                            <svg
                                                onClick={() => toggleFavorite(donor.user, false)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="currentColor"
                                                className="bi bi-heart cursor-pointer"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                            </svg>
                                        )}
                                    </span>
                                </div>
                                <div className="font-semibold text-xs mt-1 mb-2">{donor.job_title ? donor.job_title.toUpperCase() : null}</div>
                                <div className="h-14">
                                    <div className="grid grid-cols-12 text-secondary mb-1 items-center">
                                        <div className="h-4 w-4 col-span-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill mr-3" viewBox="0 0 16 16">
                                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z" />
                                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z" />
                                            </svg>
                                        </div>
                                        <div className="col-span-11 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {donor.education ? handleString(donor.education) : null}
                                            {donor.user.freeze_member.school_ranking && donor.user.freeze_member.school_ranking !== "empty"
                                                ? `, ${SchoolRankingMap[donor.user.freeze_member.school_ranking]}`
                                                : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 text-secondary mb-2">
                                        <div className="h-4 w-4 col-span-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill mr-3" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg>
                                        </div>
                                        <div className="col-span-11 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {donor.race
                                                ? donor.race.value
                                                      .map((s) => {
                                                          if (["black_african_american", "indigenous_american_alaska_native"].includes(s)) {
                                                              races[s] = s;
                                                          }
                                                          return RaceMap[s];
                                                      })
                                                      .join(", ")
                                                      .replaceAll(" or ", "/")
                                                : null}
                                            {getEthnicities(donor.mother_ethnicities, races)}
                                            {getEthnicities(donor.father_ethnicities, races)}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-[#393939]">{donor.user.freeze_member.profile_bio}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DonorSearch;

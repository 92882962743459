import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";

const Favorites = ({ user }) => {
    const [favorites, setFavorites] = useState([]);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/${user.id}/activity/favorites`,
                    method: "GET",
                });

                const { favorites } = response;

                setFavorites(favorites);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching favorites:", error);
                setHasError(error);
            }
        };

        fetchFavorites();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToFavoriteUserPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    return (
        <div className="mx-30 mb-10">
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-40 text-left py-2 border-b border-dashed border-gray-300">Favorite date</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Donor code</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Location</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Travel</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Profile</th>
                    </tr>
                </thead>
                <tbody>
                    {favorites &&
                        favorites.map((fave) => {
                            return (
                                <tr key={fave.id}>
                                    <td className="w-40 text-left py-2 border-b border-dashed border-gray-300">{extractDate(fave.created_at)}</td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <div onClick={() => navigateToFavoriteUserPage(fave.favorite_id)}>{fave.favorite_user.donorCode}</div>
                                    </td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                                        {fave.favorite_user.city}
                                        {", "}
                                        {fave.favorite_user.state}
                                    </td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">{fave.favorite_user.freeze_member.travel_availability}</td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300" onClick={() => navigateToFavoriteUserPage(fave.favorite_id)}>
                                        GO
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Favorites;

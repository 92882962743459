export const fmStatuses = [
    {
        label: "APP STARTED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_in_progress",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "APP SUBMITTED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_submission",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "APP SUB. HOLD",
        bg: "yellow",
        color: "secondary",
        value: "application_subhold",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "APP APPROVED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_approved",
        abraUpdates: [
            "Completes 'Submit your Split program application' task", 
            "Activates 'Sign your Split Member agreement' task (if eligible: must have 'Meet with our team' task complete)",
            "Updates status to PPCompleted (if eligible: must have 'Finalize your profile' and 'Add photos to your profile' tasks complete)", 
        ],
        iterableEvents: [
            "Sends FreezeApplicationReviewed event to iterable",
            "Sends FreezePPComplete (if eligible for status PPCompleted)"
        ],
    },
    {
        label: "P&P COMPLETED",
        bg: "#F67EDF",
        color: "white",
        value: "pp_completed",
        abraUpdates: [],
        iterableEvents: [
            "Sends FreezePPComplete event to iterable"
        ],
    },
    {
        label: "P&P UNDER REVIEW",
        bg: "#D2FC99",
        color: "black",
        value: "pp_under_review",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "P&P REVISIONS",
        bg: "#D2FC99",
        color: "gold",
        value: "pp_revisions",
        abraUpdates: [
            "Activates 'Add photos to your profile' task",
            "Activates 'Finalize your profile' task",
            "Activates 'Submit your profile for review' task"
        ],
        iterableEvents: [
            "Sends FreezeProfileRevisions event to iterable"
        ],
    },
    {
        label: "P&P QA",
        bg: "#D2FC99",
        color: "black",
        value: "pp_qa",
        abraUpdates: [
            "Completes 'Add photos to your profile' task",
            "Completes 'Finalize your profile' task",
            "Advances 'Submit your profile for review' task"
        ],
        iterableEvents: [],
    },
    {
        label: "ACTIVE",
        bg: "#60D86A",
        color: "black",
        value: "active",
        abraUpdates: [
            "Completes 'Submit your profile for review' task",
            "Activates 'Engage with our community' task",
            "Activates 'Educate yourself' task",
        ],
        iterableEvents: [
            "Sends FreezeProfileReadyToGo event to iterable",
            "Sends NewDonorAdded (if eligible: coming from status PPUnderReview, PPQA, or PPRevisions"
        ],
    },
    {
        label: "MATCHED",
        bg: "#60D86A",
        color: "black",
        value: "matched",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "ON HOLD",
        bg: "#60D86A",
        color: "black",
        value: "on_hold",
        abraUpdates: [
            "Completes 'Submit your profile for review' task",
            "Activates 'Engage with our community' task",
            "Activates 'Educate yourself' task",
        ],
        iterableEvents: [
            "Sends FreezeProfileReadyToGo event to iterable"
        ],
    },
    {
        label: "PDF ONLY",
        bg: "#60D86A",
        color: "black",
        value: "pdf_only",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "DISQUALIFIED",
        bg: "#E0605A",
        color: "white",
        value: "disqualified",
        abraUpdates: [
            "Enrolls user in Keep program and changes checklist to Keep",
            "Activates 'Complete our Keep intake form' task",
            "Activates 'Complete your consult and plan for your egg retrieval cycle' task",
            "Activates 'Transfer your eggs to long-term storage' task"
        ],
        iterableEvents: [],
    },
    {
        label: "RETIRED",
        bg: "#E0605A",
        color: "purple",
        value: "retired",
        abraUpdates: [
            "Deactivates all checklist tasks"
        ],
    },
]

export const callStatuses = [
    {
        label: "CALL NOT SCHEDULED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "call_not_scheduled",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "CALL SCHEDULED",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "call_scheduled",
        abraUpdates: [
            "Advances 'Meet with our team' task to call scheduled step",
        ],
        iterableEvents: [],
    },
    {
        label: "CALL NO SHOW",
        bg: "#CACD9D",
        color: "red",
        value: "call_no_show",
        abraUpdates: [
            "Advances 'Meet with our team' task to call no show step",
        ],
        iterableEvents: [
            "Sends FreezeCallNoShow event to iterable"
        ],
    },
    {
        label: "CALL CANCELLED",
        bg: "#CACD9D",
        color: "red",
        value: "call_cancelled",
        abraUpdates: [
            "Advances 'Meet with our team' task to call cancelled step",
        ],
        iterableEvents: [],
    },
    {
        label: "CALL COMPLETED",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "call_completed",
        abraUpdates: [
            "Completes 'Meet with our team' task",
            "Activates 'Sign your Split Member agreement' task (if eligible: 'Submit your Split program application' must be complete)"
        ],
        iterableEvents: [
            "Sends FreezeCallComplete event to iterable"
        ],
    },
]

export const agreementStatuses = [
    {
        label: "AGR. NOT SENT",
        bg: "#D2E9E6",
        color: "secondary",
        value: "agreement_not_sent",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "AGR. SENT",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "agreement_sent",
        abraUpdates: [],
        iterableEvents: [
            "Sends FreezeAgreementSent event to iterable"
        ],
    },
    {
        label: "AGR. SIGNED",
        bg: "#C6E6FD",
        color: "#04375C",
        value: "agreement_completed",
        abraUpdates: [
            "Generates a donor code if none exists",
            "Completes 'Sign your Split Member agreement' task",
            "Activates 'Complete your AMH blood test' task",
            "Activates 'Submit your profile for review' task (if eligible: must have 'Finalize your profile' and 'Add photos to your profile' tasks complete)"
        ],
        iterableEvents: [
            "Sends FreezeAgreementSigned event to iterable"
        ],
    },
    {
        label: "AGR. VOIDED",
        bg: "#E0605A",
        color: "white",
        value: "agreement_voided",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "AGR. DECLINED",
        bg: "#E0605A",
        color: "white",
        value: "agreement_declined",
        abraUpdates: [],
        iterableEvents: [],
    },
]

export const amhStatuses = [
    {
        label: "AMH NOT STARTED",
        bg: "#E6E6FA",
        color: "black",
        value: "amh_not_started",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "LAB ORDER SENT",
        bg: "#DDA0DD",
        color: "black",
        value: "amh_lab_order_sent",
        abraUpdates: [
            "Advances 'Complete your AMH blood test' task to lab sent step",
        ],
        iterableEvents: [
            "Sends FreezeAMHLabOrderSent event to iterable"
        ],
    },
    {
        label: "TEST SCHEDULED",
        bg: "#D8BFD8",
        color: "black",
        value: "amh_test_scheduled",
        abraUpdates: [
            "Advances 'Complete your AMH blood test' task to test scheduled step"
        ],
        iterableEvents: [
            "Sends FreezeAMHLabScheduled event to iterable"
        ],
    },
    {
        label: "RESULTS PENDING",
        bg: "#EE82EE",
        color: "black",
        value: "amh_results_pending",
        abraUpdates: [
            "Advances 'Complete your AMH blood test' task to results pending step"
        ],
        iterableEvents: [
            "Sends FreezeAMHLabComplete event to iterable"
        ],
    },
    {
        label: "PASSED F&F (>3.0)",
        bg: "#800080",
        color: "white",
        value: "amh_test_passed_frf",
        abraUpdates: [
            "Completes 'Complete your AMH blood test' task"
        ],
        iterableEvents: [
            "Sends FreezeAMHLabComplete event to iterable"
        ],
    },
    {
        label: "PASSED FRSH (2.0-3.0)",
        bg: "#662d91",
        color: "white",
        value: "amh_test_passed_fr",
        abraUpdates: [
            "Completes 'Complete your AMH blood test' task"
        ],
        iterableEvents: [
            "Sends FreezeAMHLabComplete event to iterable"
        ],
    },
    {
        label: "FAILED (<2.0)",
        bg: "#E0605A",
        color: "white",
        value: "amh_test_failed",
        abraUpdates: [],
        iterableEvents: [
            "Sends FreezeAMHLabComplete event to iterable"
        ],
    },
]

export const migrationStatuses = [
    {
        label: "NO MIGRATION STATUS",
        bg: "#D2E9E6",
        color: "secondary",
        value: "none",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "ACTIVATION REQUIRED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "activation_required",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "ACTIVATION SENT",
        bg: "yellow",
        color: "secondary",
        value: "activation_sent",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "QUESTIONS NEEDED",
        bg: "#D2FC99",
        color: "black",
        value: "questions_required",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "MIGRATION COMPLETE",
        bg: "#60D86A",
        color: "black",
        value: "activation_complete",
        abraUpdates: [],
        iterableEvents: [],
    },
]

export const CycleTypes = [
    {
        label: "Empty",
        value: null,
        bg: "#bcf5f9",
        color: "#04375C",
    },
    {
        label: 'FS 50/50',
        value: 'fs_5050',
        bg: "#E0605A",
        color: "#04375C",
    },
    {
        label: 'FZ 50/50',
        value: 'fz_5050',
        bg: "#3a80ec",
        color: "#04375C",
    },
    {
        label: 'FS 2 1:1',
        value: 'fs_2_11',
        bg: "#0229bf",
        color: "white",
    },
    {
        label: 'FZ 2 1:1',
        value: 'fz_2_11',
        bg: "#080b6c",
        color: "white",
    },
    {
        label: 'FS 2 50/50',
        value: 'fs_2_5050',
        bg: "#006666",
        color: "white",
    },
    {
        label: 'FZ 2 50/50',
        value: 'fz_2_5050',
        bg: "#D2FC99",
        color: "#04375C",
    },
]

export const scStatuses = [
    {
        label: "FZ NOT REVIEWED",
        bg: "#bcf5f9",
        color: "#04375C",
        value: "not_yet_reviewed_for_frozen",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ PENDING REVIEW",
        bg: "#89c5fd",
        color: "#04375C",
        value: "pending_review_for_frozen",
        abraUpdates: [],
        iterableEvents: [],
    },

    {
        label: "FS ONLY",
        bg: "#E0605A",
        color: "#04375C",
        value: "not_a_candidate_for_frozen",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ SELECTED",
        bg: "#3a80ec",
        color: "#04375C",
        value: "frozen_selected",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ SIGNED",
        bg: "#0229bf",
        color: "white",
        value: "frozen_signed",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ SCREENING",
        bg: "#080b6c",
        color: "white",
        value: "frozen_screening",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ 1ST CYCLING",
        bg: "#006666",
        color: "white",
        value: "frozen_first_cycling",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ 2ND CYCLING",
        bg: "#006666",
        color: "white",
        value: "frozen_second_cycling",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ CYCLE COMPLETE",
        bg: "#D2FC99",
        color: "#04375C",
        value: "frozen_cycle_complete",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ DECLINED",
        bg: "#E0605A",
        color: "white",
        value: "frozen_declined",
        abraUpdates: [],
        iterableEvents: [],
    },
    {
        label: "FZ DISQUALIFIED",
        bg: "#E0605A",
        color: "white",
        value: "frozen_disqualified",
        abraUpdates: [],
        iterableEvents: [],
    },
]

export const ipStatuses = [
    {
        label: "INTAKE INCOMPLETE", 
        bg: "rgb(251 191 36)",
        color: "black",
        value: "intake_incomplete"
    },
    {
        label: "UNMATCHED", 
        bg: "rgb(56 189 248)",
        color: "black",
        value: "unmatched"
    },
    // {
    //     label: "CALL PENDING", 
    //     bg: "rgb(192 132 252)",
    //     color: "white",
    //     value: "call_pending"
    // },
    // {
    //     label: "CALL SCHEDULED", 
    //     bg: "rgb(244 114 182)",
    //     color: "black",
    //     value: "call_scheduled"
    // },
    // {
    //     label: "CALL NO SHOW", 
    //     bg: "rgb(225 29 72)",
    //     color: "white",
    //     value: "call_no_show"
    // },
    // {
    //     label: "CONFIRM PREFERENCES", 
    //     bg: "rgb(134 239 172)",
    //     color: "black",
    //     value: "confirm_preferences"
    // },
    // {
    //     label: "SIGN AGREEMENT", 
    //     bg: "rgb(163 230 53)",
    //     color: "black",
    //     value: "sign_family_agreement"
    // },
    // {
    //     label: "CONFIRM MATCH", 
    //     bg: "rgb(20 184 166)",
    //     color: "white",
    //     value: "confirm_match"
    // },
    // {
    //     label: "MATCH FS", 
    //     bg: "rgb(132 204 22)",
    //     color: "black",
    //     value: "matched_fresh"
    // },
    // {
    //     label: "MATCH FZ", 
    //     bg: "rgb(3 105 161)",
    //     color: "white",
    //     value: "matched_frozen"
    // },
    {
        label: "MATCHED", 
        bg: "rgb(132 204 22)",
        color: "black",
        value: "matched"
    },
]

export const matchStatuses = [
    {
        label: "ACTIVE",
        bg: "rgb(132 204 22)",
        color: "black",
        value: "active"
    },
    {
        label: "CANCELLED",
        bg: "rgb(220 38 38)",
        color: "black",
        value: "cancelled"
    },
    {
        label: "COMPLETED",
        bg: "rgb(6, 89, 81)",
        color: "white",
        value: "completed"
    },
]

export const matchStages = [
    {
        label: "STAGE 1",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_1",
    },
    {
        label: "STAGE 2",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_2",
    },
    {
        label: "STAGE 3",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_3",
    },
    {
        label: "STAGE 4",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "stage_4",
    },
    {
        label: "STAGE 5",
        bg: "#CACD9D",
        color: "red",
        value: "stage_5",
    },
    {
        label: "STAGE 6",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "stage_6",
    },
    {
        label: "STAGE 7",
        bg: "#C6E6FD",
        color: "#04375C",
        value: "stage_7",
    },
    {
        label: "STAGE 8",
        bg: "#F67EDF",
        color: "white",
        value: "stage_8",
    },
    {
        label: "STAGE 9",
        bg: "#D2FC99",
        color: "black",
        value: "stage_9",
    },
    {
        label: "STAGE 10",
        bg: "#D2FC99",
        color: "black",
        value: "stage_10",
    },
    {
        label: "CANCELLED",
        bg: "#E0605A",
        color: "white",
        value: "cancelled",
    }
]

export const paymentStatuses = [
    {
        label: "DEPOSIT PAID",
        bg: "rgb(132 204 22)",
        color: "black",
        value: "deposit_paid"
    },
    {
        label: "COORD. FEE PAID",
        bg: "rgb(6, 89, 81)",
        color: "white",
        value: "coordination_fee_paid"
    },
    {
        label: "REFUND",
        bg: "rgb(220 38 38)",
        color: "black",
        value: "refund"
    },
    {
        label: "UNPAID",
        bg: "rgb(255, 218, 86)",
        color: "black",
        value: "unpaid"
    },
]

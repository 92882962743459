import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../App";

const DevSettings = ({ options }) => {
    const { loggedInUser, request, refreshUser } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (!loggedInUser) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    if (!loggedInUser) {
        return null;
    }

    if (loggedInUser.email !== "sophie@cofertility.com") {
        return null;
    }

    return <div className="flex"></div>;
};

export default DevSettings;

import { useContext, useEffect } from "react";
import PublicProfile from "../profile/Profile";
import { IPDashboardContext } from "./Dashboard";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../App";

const DonorProfile = () => {
    const params = useParams();
    const { setCurrentPage, addToast } = useContext(IPDashboardContext);
    const navigate = useNavigate();
    const { loggedInUser } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    useEffect(() => {
        // TODO - update to allow them to view the page of their match, need to possibly pull in matches
        if (
            loggedInUser &&
            loggedInUser.intended_parent.family.status === "matched" &&
            !loggedInUser.intended_parent.family.multimatch &&
            loggedInUser.intended_parent.family.matches &&
            loggedInUser.intended_parent.family.matches.filter((x) => x.freeze_member_id === params.userId).length === 0
        ) {
            navigate("/family/checklist");
        } else if (loggedInUser && loggedInUser.intended_parent.family.matches && loggedInUser.intended_parent.family.matches.filter((x) => x.freeze_member_id === params.userId).length === 1) {
            setCurrentPage("match");
        } else {
            setCurrentPage("profile");
        }
    }, [loggedInUser, navigate, params, setCurrentPage]);

    return (
        <div>
            <PublicProfile addToast={addToast} ip={true} viewPhotos={() => navigate(`/family/donors/${params.userId}/photos`)} />
        </div>
    );
};

export default DonorProfile;

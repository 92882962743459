import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ipStatuses, migrationStatuses } from "../../../util/statuses";
import { Filter } from "../../filter/Filter";
import { InputFilter } from "../../filter/InputFilter";
import { DateFilter } from "../../filter/DateFilter";
import { DropdownSelectFilter } from "../../filter/DropdownSelectFilter";
import { AppContext } from "../../../App";

const IntendedParents = ({ handleIpCount }) => {
    const [intendedParents, setIntendedParents] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [loginDateRange, setLoginDateRange] = useState([null, null]);
    const [joinDateRange, setJoinDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("last_login");
    const [orderDir, setOrderDir] = useState("desc");

    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const [migrationStatusDropdownOpen, setMigrationStatusDropdownOpen] = useState(false);
    const [selectedMigrationStatuses, setSelectedMigrationStatuses] = useState([]);

    const [hasError, setHasError] = useState(null);

    const filterRef = useRef(null);
    const statusDropdownRef = useRef(null);
    const migrationStatusDropdownRef = useRef(null);

    const navigate = useNavigate();
    const { request } = useContext(AppContext);

    useEffect(() => {
        document.title = "Staff | Intended Parents";
        const fetchIntendedParents = async () => {
            try {
                let filterString = "";
                if (filters.length) {
                    filters.forEach((filter) => {
                        if (filter.name.includes("range")) {
                            if (filter.value[0] !== null) {
                                filterString += `&${filter.name}_min=${filter.value[0]}`;
                            }
                            if (filter.value[1] !== null) {
                                filterString += `&${filter.name}_max=${filter.value[1]}`;
                            }
                        } else if (filter.name === "status") {
                            filter.value.forEach((v) => {
                                filterString += `&${filter.name}=${v}`;
                            });
                        } else {
                            filterString += `&${filter.name}=${filter.value}`;
                        }
                    });
                }

                const response = await request({
                    url: `/staff/intended-parents?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { intendedParents, intendedParentCount } = response;
                handleIpCount(intendedParentCount);

                setHasMore(intendedParents.length > 0);
                if (page !== 1) {
                    setIntendedParents((prev) => [...prev, ...intendedParents]);
                } else {
                    setIntendedParents(intendedParents);
                }
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchIntendedParents();
    }, [request, page, orderBy, orderDir, filters, handleIpCount]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilters(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setStatusDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (migrationStatusDropdownRef.current && !migrationStatusDropdownRef.current.contains(event.target)) {
                setMigrationStatusDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const navigateToIntendedParentPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop === "last_name") {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleStatusClick = (statusArray, setStatusArray, setDropdownOpen, name, status) => {
        let ns = [];
        if (statusArray.includes(status)) {
            ns = structuredClone(statusArray).filter((s) => s !== status); // Remove if already selected
        } else {
            ns = [...structuredClone(statusArray), status]; // Add if not selected
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);
        if (ns.length) {
            filteredFilters.push({
                name: name,
                value: ns,
            });
        }
        setPage(1);
        setFilters(filteredFilters);
        setStatusArray(ns);
        setDropdownOpen(false);
    };

    const clearAllStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        setFilters(filteredFilters);
        setSelectedStatuses([]);
        setPage(1);
    };

    const clearAllMigrationStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "migration_status");
        setFilters(filteredFilters);
        setSelectedMigrationStatuses([]);
        setPage(1);
    };

    const clearAllFilters = () => {
        setFilters([]);
        setLoginDateRange([null, null]);
        setJoinDateRange([null, null]);
        setSelectedStatuses([]);
        setPage(1);
    };

    return (
        <div className="mx-10 mb-20">
            <div key={"filter-box"} className="mb-5" style={{ position: "fixed", top: 80, right: 20, zIndex: 10 }}>
                <button
                    key={"filter-button"}
                    className="bg-white text-secondary border border-secondary rounded-full py-2 px-6 font-semibold hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filters
                </button>
                {showFilters ? (
                    <Filter isStaff={true} filterRef={filterRef} clearAllFilters={clearAllFilters} showFilters={showFilters} setShowFilters={setShowFilters}>
                        {[
                            <InputFilter
                                key={"name"}
                                name="name"
                                title="Name"
                                placeholder="IP Name"
                                clearState={clearNameSearch}
                                clearValue="name"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <InputFilter
                                key={"email"}
                                name="email"
                                title="Email"
                                placeholder="Email..."
                                clearState={clearNameSearch}
                                clearValue="email"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <DateFilter
                                key={"login_range"}
                                name="login_range"
                                title="Last Login"
                                placeholder="Login Range"
                                dateRange={loginDateRange}
                                setDateRange={setLoginDateRange}
                                handleDateFilter={handleDateFilter}
                            />,
                            <DateFilter
                                key={"join_range"}
                                name="join_range"
                                title="Join Date"
                                placeholder="Join Range"
                                dateRange={joinDateRange}
                                setDateRange={setJoinDateRange}
                                handleDateFilter={handleDateFilter}
                            />,
                            <DropdownSelectFilter
                                key={"status"}
                                title="Status"
                                name="status"
                                label="Filter by status"
                                dropdownRef={statusDropdownRef}
                                dropdownOpen={statusDropdownOpen}
                                setDropdownOpen={setStatusDropdownOpen}
                                options={ipStatuses}
                                selected={selectedStatuses}
                                setSelected={setSelectedStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                key={"migration_status"}
                                title="Migration status"
                                name="migration_status"
                                label="Filter by migration status"
                                dropdownRef={migrationStatusDropdownRef}
                                dropdownOpen={migrationStatusDropdownOpen}
                                setDropdownOpen={setMigrationStatusDropdownOpen}
                                options={migrationStatuses}
                                selected={selectedMigrationStatuses}
                                setSelected={setSelectedMigrationStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllMigrationStatuses}
                            />,
                        ]}
                    </Filter>
                ) : null}
            </div>
            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("last_login")} className="flex items-center">
                                <span className="mr-1">Last Login</span>
                                <span className="flex items-center">
                                    {orderBy === "last_login" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_login" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/6 text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Join Date</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("last_name")} className="flex items-center">
                                <span className="mr-1">Intended Parent</span>
                                <span className="flex items-center">
                                    {orderBy === "last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Email</span>
                            </span>
                        </th>
                        <th className="w-1/3 min-w-[250px] text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Status</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {intendedParents &&
                        intendedParents.map((member) => {
                            const ipStatus = ipStatuses.filter((status) => status.value === member.intended_parent.family.status)[0];
                            const migrationStatus = member.migration_status ? migrationStatuses.filter((status) => status.value === member.migration_status)[0] : null;

                            return (
                                <tr key={member.id} className="w-full">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(member.lastLogin)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(member.createdAt)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <Link to={`/staff/intended-parents/${member.id}`}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {member.firstName ? member.firstName.charAt(0).toUpperCase() : ""}
                                            </span>
                                            <span className="font-medium">
                                                {member.firstName ? member.firstName : ""} {member.lastName ? member.lastName : ""}
                                            </span>
                                        </Link>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">{member.email}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <div className="flex flex-col xl:w-full xl:grid xl:grid-cols-2 xl:gap-x-5 gap-y-2 pr-4">
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: ipStatus.bg, color: ipStatus.color }}>
                                                {ipStatus.label}
                                            </div>
                                            {migrationStatus ? (
                                                <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: migrationStatus.bg, color: migrationStatus.color }}>
                                                    {migrationStatus.label}
                                                </div>
                                            ) : null}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default IntendedParents;

import React from "react";

const ToastViewer = (toast, dequeueToast) => {
    return (
        <div class="fixed bottom-4 right-4 z-50 w-[200px] pb-4 mx-auto m-8 sm:grid grid-cols-10 bg-white border-black bg-cover rounded-xl">
            <div class="col-span-9 px-4 p-2">
                <div class="font-semibold text-md">{toast.heading}</div>
                <div class="text-sm">{toast.message}</div>
            </div>
            <img onClick={dequeueToast} class="col-span-1 justify-self-end m-2 cursor-pointer" src={`${process.env.PUBLIC_URL}/images/close_icon.svg`} alt="" width="24" height="24" />
        </div>
    );
};

export default ToastViewer;
